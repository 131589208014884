var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tikuAnswer"},[_c('div',{staticClass:"tikuAnswer_header"},[_c('div',{staticClass:"left"},[_vm._m(0),_c('div',{staticClass:"info"},[_c('div',[_vm._v(" 姓  名： "),_c('span',[_vm._v(_vm._s(_vm.userInfo.userName))])]),_vm._m(1),_vm._m(2),_vm._m(3)])]),_vm._m(4),_c('div',{staticClass:"right"},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"title1"},[_vm._v("科目剩余时间："+_vm._s(_vm.str))])]),_c('div',{staticClass:"btn"},[_c('el-button',{staticClass:"sub",attrs:{"type":"danger"},on:{"click":_vm.submitPaper}},[_vm._v("交卷")])],1)])]),_c('div',{staticClass:"tikuAnswer_main"},[(_vm.AnswerList)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSHow),expression:"isSHow"}],staticClass:"tikuAnswer_main_left"},[_c('answerCard',{ref:"answerCard",attrs:{"AnswerList":_vm.AnswerList},on:{"jumpTo":_vm.jumpTo}})],1):_vm._e(),_c('div',{staticClass:"sanjiao",class:_vm.isSHow?'shows':'hides',on:{"click":_vm.showFn}},[_c('div',{class:_vm.isSHow?'hidesanjiao':'showsanjiao'})]),(_vm.AnswerList)?_c('div',{staticClass:"tikuAnswer_main_right"},[_vm._m(5),_c('questionArea',{ref:"questionArea",attrs:{"AnswerList":_vm.AnswerList},on:{"allEventFn":_vm.allEventFn,"submitPaper":_vm.submitPaper,"submitPaperNew":_vm.submitPaperNew,"changePaper":_vm.changePaper}})],1):_vm._e()]),_c('div',{staticClass:"footer"})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../assets/tiku/blue.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" 性  别： "),_c('span',[_vm._v("X")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" 准考证号： "),_c('span',[_vm._v("1111111111")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" 证件号码： "),_c('span',[_vm._v("1111111111111111111")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"middle"},[_c('div',[_vm._v("2024年度全国会计专业技术初级资格考试")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"markst"},[_c('span',{staticClass:"markst_1"},[_vm._v("试卷")])])
}]

export { render, staticRenderFns }