<template>
  <div class="tiku_indeterminate">
    <div class="tiku_indeterminate_header">{{ setTitle(curData) }}</div>
    <div class="tiku_indeterminate_question">
      <div style="font-weight: bold;font-size: 17px;">
        第 {{data.questionIndex}} 题
      </div>
      <div class="tiku_indeterminate_question_title update_font_size">
<!--        <span>{{data.questionIndex}}、</span>-->
        <span v-html="data.subjectTitle"></span>
        <img v-if="data.subjectTitlePic" :src="data.subjectTitlePic" class="subjectTitlePic">
      </div>
    </div>
    <div class="tiku_indeterminate_inputBox">
      <div class="tiku_indeterminate_inputBox_header">
        <div class="tiku_indeterminate_inputBox_header_item">
          <span v-for="(item,index) in data.subjectList" :key="index" :class="smallQuestionIndex == index ? 'checked' :''" @click="tabSmallQuestion(item,index)">第{{index+1}}小题</span>
        </div>
      </div>
    </div>
    <div class="tiku_indeterminate_question_text">
      {{curData.subjectTitle}}
      <img v-if="curData.subjectTitlePic" :src="curData.subjectTitlePic" class="subjectTitlePic">
    </div>
    <div class="m-tb-10 plr20">
      <vue-ueditor-wrap v-model="testTextarea" :config="myConfig"></vue-ueditor-wrap>
    </div>

    <!--答案解析-->
    <div class="tiku_indeterminate_radioAnalysis_nalysisWarp">
      <div v-if="isShowJX" class="tiku_indeterminate_radioAnalysis_header">
        <div class="tiku_indeterminate_radioAnalysis_header_MyQuestion">
          <p>
            <span>您的作答：</span><span>{{ setMyAnwser(anaList) }}</span>
          </p>
          <p>
            <span>参考答案：</span><span>{{ setRightAnwser(anaList) }}</span>
          </p>
          <p
              v-if="
            questionObj.totalAccuracy != '' &&
            questionObj.totalAccuracy != null &&
            questionObj.totalAccuracy != undefined
          "
          >
            <span>全站正确率：</span>
            <span>{{ questionObj.totalAccuracy }}</span>
          </p>
        </div>
        <div class="tiku_indeterminate_radioAnalysis_header_numQuestion">
          <p>
            <span>累计作答：</span><span>{{ questionObj.countNumber }}次</span>
          </p>
          <p>
            <span>答对：</span><span>{{ questionObj.rightNumber }}次</span>
          </p>
          <p>
            <span>答错：</span>
            <span>{{ questionObj.errorNumber }}次</span>
          </p>
        </div>
        <div class="tiku_indeterminate_radioAnalysis_header_anwserAna">
          <div class="tiku_indeterminate_radioAnalysis_header_anwserAna_title">答题解析：</div>
          <div class="tiku_indeterminate_radioAnalysis_header_anwserAna_text">
            <p v-html="anaList.analysisText"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {subject_analysis,questionStatistics} from "@/api/exam";
import VueUeditorWrap from 'vue-ueditor-wrap';
import { Loading } from "element-ui";
export default {
  props: ["data", "dataIndex"],
  components: {VueUeditorWrap},
  data() {
    return {
      checkList: [],
      isShowJX: false,
      smallQuestionIndex: 0,
      testTextarea: '', // 简答题的textarea
      myConfig: {
        // 如果需要上传功能,找后端小伙伴要服务器接口地址
        serverUrl: '',
        // 你的UEditor资源存放的路径,相对于打包后的index.html
        UEDITOR_HOME_URL: '/UEditor/',
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 300,
        // 初始容器宽度
        initialFrameWidth: '100%',
        // 关闭自动保存
        enableAutoSave: false,
        // 字数
        wordCount: false,
        // 元素路径
        elementPathEnabled: false,
        // 图片拉伸
        imageScaleEnabled: false,
        // 右键菜单
        // enableContextMenu: false,
        // 自定义工具栏，需要额外选项可以参考ueditor.config.js
        toolbars: [
          [
            'bold', 'italic', 'underline', '|', 'fontsize', 'fontfamily', '|', 'kityformula',
            'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|',
          ]
        ],
        contextMenu: [
          {
            label: '',
            cmdName: 'selectall',
          },
          {
            label: '',
            cmdName: 'cleardoc',
            exec: function () {
              if (confirm('确定清空当前文档么？')) {
                this.execCommand("cleardoc");
              }
            }
          }
        ]
      },
      anaList: {},
      questionObj:{},
      curData: {}
    };
  },
  name: "shortAnswer",
  methods: {
    changeAnswer(index){
      this.setSubmitAnswer();
      this.smallQuestionIndex = index;
      this.isShowJX = false;
      this.curData = this.data.subjectList[index];
      this.testTextarea = this.curData.doneAnswer;
    },
    setSubmitAnswer(){
      let subjectId = this.data.subjectList[this.smallQuestionIndex].subjectId;
      let obj = {
        subjectId: subjectId,
        answerId: '',
        dataIndex: this.dataIndex,
        type: "shortAnswer",
        doneAnswer:this.testTextarea
      };
      this.$emit("answerRadio", obj);

    },
    getAnswer(){
      this.setSubmitAnswer();
      return this.smallQuestionIndex;
    },
    setAnswer(index){
      this.smallQuestionIndex = index;
      this.isShowJX = false;
      this.curData = this.data.subjectList[index];
      this.testTextarea = this.curData.doneAnswer;
    },
    setMyAnwser(list) {
      if (list.answerList && list.answerList.length > 0) {
        let val = list.answerList.filter((item) => {
          return list.doneAnswerIds === item.answerId;
        });
        if (val.length == 0) {
          return "/";
        } else {
          return val[0].indexes;
        }
      }
    },
    setRightAnwser(list) {
      console.log(list);
      if (list.answerList && list.answerList.length > 0) {
        let val = [];
        list.answerList.map((item) => {
          if(item.isRight === 1) {
            val.push(item.indexes)
          }
        });
        return val.join(',');
      }
    },
    tabSmallQuestion(val,index) {
      let subjectId = this.data.subjectList.length?this.data.subjectList[this.smallQuestionIndex].subjectId:this.data.subjectId;
      let obj = {
        subjectId: subjectId,
        answerId: val,
        dataIndex: this.dataIndex,
        type: "shortAnswer",
        doneAnswer:this.testTextarea
      };
     
      this.smallQuestionIndex = index;
      this.isShowJX = false;
      
      this.$emit("answerRadio", obj);
      this.$emit("changePaper", this.dataIndex,index);
      this.curData = this.data.subjectList[index];
      this.testTextarea = this.curData.doneAnswer;
      
    },
    changeQuestion(type) {
      console.log(this.smallQuestionIndex);
      if(type === 'prev'&&this.smallQuestionIndex===0) {

      }else if(type === 'next'&&this.smallQuestionIndex===this.data.subjectList.length-1) {

      }else {
        let index = type === 'prev'?this.smallQuestionIndex-1:this.smallQuestionIndex+1;
        this.tabSmallQuestion(index);
      }
    },
    startAnaly() {
      this.loading = Loading.service({
        text: "解析中...",
        background: "rgba(0, 0, 0, 0.1)",
      });
      subject_analysis({ subjectId: this.curData.subjectId }).then((res) => {
        this.isShowJX = !this.isShowJX;
        this.anaList = res.data.data;
        this.loading.close();
      });
      questionStatistics({subjectId: this.curData.subjectId}).then((res)=>{
        this.questionObj = res.data.data;
      })
    },
    setTitle(item){
      switch(item.classify){
        case "1":
          return "单项选择题";
          break;
        case "2":
          return "多项选择题";
          break;
        case "3":
          return "判断题";
          break;
        case "4":
          return "不定项选择题";
          break;
        case "5":
          return "计算分析题";
          break;
        case "6":
          return "综合题";
          break;
        case "7":
          return "简答题";
          break;
        case "8":
          return "计算回答题";
          break;
        case "9":
          return "案例分析题";
          break;
        case "10":
          return "计算题";
          break;
        case "11":
          return "综合分析题";
          break;

      }

    },
  },
  created() {
    this.curData = this.data.subjectList[0];
    this.testTextarea = this.curData.doneAnswer;
    // this.smallQuestionIndex = this.curData.subjectId;
  },
  mounted() {
    // this.$nextTick(()=>{
    //   this.curData = this.data.subjectList[0];
    //   this.testTextarea = this.curData.doneAnswer;
    // })
    
  },
};
</script>
<style lang="less">
.tiku_indeterminate_header{
  border-bottom: 1px solid #9FB7F7;
}
</style>