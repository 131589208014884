<template>
  <div class="tikuAnswer">
    <div class="tikuAnswer_header">
      <div class="left">
        <div class="img">
          <img src="../../assets/tiku/blue.jpg" alt="">
        </div>
        <div class="info">
          <div>
            姓&nbsp&nbsp名：
            <span>{{userInfo.userName}}</span>
          </div>
          <div>
            性&nbsp&nbsp别：
            <span>X</span>
          </div>
          <div>
            准考证号：
            <span>1111111111</span>
          </div>
          <div>
            证件号码：
            <span>1111111111111111111</span>
          </div>
        </div>
      </div>
      <div class="middle">
        <div>2024年度全国会计专业技术初级资格考试</div>
        <!-- <div>初级会计实务（演示版）</div> -->
      </div>
      <div class="right">
        <div class="info">
          <div class="title1">科目剩余时间：{{ str }}</div>
          <!-- <div class="title2" v-if="questionSource == 246">初级会计实务：做答中</div>
          <div class="title3" v-else>初级会计实务：科目未作答</div>
          <div class="title2" v-if="questionSource == 247">经济法基础：做答中</div>
          <div class="title3" v-else>经济法基础：科目未作答</div> -->
        </div>
        <div class="btn">
          <el-button class="sub" type="danger" @click="submitPaper">交卷</el-button>
        </div>
      </div>
    </div>

    <!-- main -->
    <div class="tikuAnswer_main">
      <!-- main_left -->
      <div class="tikuAnswer_main_left" v-show="isSHow" v-if="AnswerList">
        <answerCard ref="answerCard" :AnswerList="AnswerList" @jumpTo="jumpTo"></answerCard>
      </div>

      <div class="sanjiao" :class="isSHow?'shows':'hides'" @click="showFn">
        <div :class="isSHow?'hidesanjiao':'showsanjiao'"></div>
      </div>

      <!-- main_right -->
      <div class="tikuAnswer_main_right"  v-if="AnswerList">
        <div class="markst">
          <span class="markst_1">试卷</span>
        </div>
        <questionArea
          :AnswerList="AnswerList"
          ref="questionArea"
          @allEventFn="allEventFn"
          @submitPaper="submitPaper"
          @submitPaperNew="submitPaperNew"
          @changePaper="changePaper"
        ></questionArea>
      </div>
    </div>
    <div class="footer"></div>
  </div>
</template>

<script>
import answerCard from "@/views/computerExam/components/answerCard";
import questionArea from "@/views/computerExam/components/questionArea";
import { Loading } from "element-ui";
import {
  subject_list,
  save_subject,
  add_sign,
  add_collect,
  deleted_collect,
  assignment_paper,
  subjectPaperList,
  savePaper_subject,
  assignment_exercise,
  error_exercise_subjectList,
  error_paper_subjectList,
  analysis_list,
} from "@/api/exam";

export default {
  name: "tiku1",
  components: {
    answerCard,
    questionArea,
  },
  data() {
    return {
      isSHow: true,
      AnswerList: [],
      hour: 0, //定义时，分，秒，毫秒并初始化为0；
      minute: 0,
      second: 0, //秒
      time: "",
      str: "",
      second_time: 0,
      secondId: "",
      firstId: "",
      tabIndex: "",
      twoIindex: "",
      examInfo: {},
      testSecond_time: 0,
      answerTimers: "",
      paperAnswerTime: "",
      userInfo : '',
      questionSource:null,
    };
  },
  created() {
    this.loading = Loading.service({
      text: "Loading...",
      background: "rgba(0, 0, 0, 0.1)",
    });
  },
  mounted() {
    var that = this;
    var pageQuery = that.$route.query;
    this.userInfo = JSON.parse(localStorage.getItem("setuserInfo"));
    var examTextInfo = localStorage.getItem("tiku_examText");
    if (examTextInfo) {
      examTextInfo = JSON.parse(examTextInfo);
      that.examInfo = examTextInfo.chapterListItem;
      if (pageQuery && pageQuery.value) {
        this.num = pageQuery.value;
      } else {
        this.num = examTextInfo.value;
      }
      that.secondId = examTextInfo.secondId;
      that.firstId = examTextInfo.firstId;
    }
    that.tabIndex = pageQuery.tabIndex;
    that.questionSource = pageQuery.type;
    this.init();

  },
  watch: {},
  methods: {
    showFn() {
      this.isSHow = !this.isSHow;
    },
    /*答题卡跳转题目*/
    jumpTo(index,subjectList,index1) {
      this.$refs.questionArea.setActiveQuestion(index,subjectList,index1);
    },
    initTestReportInfo(val,num){
      var testReportInfo = new Object();
      let that = this;
      if(val == 2){
        testReportInfo = {
          examInfo: that.examInfo,
          num: num,
          twoIindex: that.twoIindex,
          tabIndex: that.tabIndex,
          firstId: that.firstId,
          secondId: that.secondId,
          testPaperId: that.examInfo.testPaperId,
        }
      }else{
        testReportInfo = {
          examInfo: that.examInfo,
          num: num,
          twoIindex: that.twoIindex,
          tabIndex: that.tabIndex,
          firstId: that.firstId,
          secondId: that.secondId,
        }
          
      }
     
      localStorage.setItem("testReport", JSON.stringify(testReportInfo))
    },
    submitTestPa(){
      let pageQuery = this.$route.query;
      let tabsIndex = pageQuery.tabIndex;
      // 二级判断 做题记录的章节还是考试
      var examTextInfo = localStorage.getItem("tiku_examText");
      let examText = JSON.parse(examTextInfo);
      let items = examText.chapterListItem;
      let testPaperId = pageQuery.testPaperId;
        var obj = {
          testPaperId: testPaperId,
          terrace:'2',
        }
        assignment_paper(obj).then(res => {
          if (res.data.code == 0) {
            this.initTestReportInfo(2,2);
            this.$router.push({
              path: '/tikuReport',
              query: {
                testPaperId: testPaperId,
              }
            });
          }
        })
      
    },
    submitPaperNew(){
      //最后一题，提示交卷
      this.$confirm("您已经做到最后一题了是否提交呢", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
          }).then(() => {
            this.submitTestPa();
           
          })
          .catch(() => {});
     
    },
    submitPaper(){ // 交卷
       // 路由参数
       this.$confirm("确定交卷", "提示", {
        confirmButtonText: "确定",
        cancelButtonText:'取消',
        type: "warning"
      })
      .then(() => {
        this.submitTestPa();
      })
      .catch(() => {
           
      });
      



    },
    changePaper(index,two) {
      this.$refs.answerCard.jumpTo(index,two);
    },
    getSubjectPaperList(obj) {
      //获取试卷列表
      var that = this;
      var obj = {
        testPaperId: obj.testPaperId,
        type: obj.type,
        terrace:'2'
      };
      subjectPaperList(obj)
        .then((res) => {
          if (res.data.code == 0) {
            this.paperAnswerTime = res.data.data.paperAnswerTime;
            this.testSecond_time = this.AnswerList.timeLength;
            this.testMounted =
              Number(res.data.data.paperAnswerTime * 60) -
              res.data.data.timeLength; //总共剩余秒数

            this.testSecond = this.testMounted % 60;
            this.testSecond_time = res.data.data.timeLength;
            this.recordTime = res.data.data.timeLength;
            this.testMinute = Math.floor(this.testMounted / 60);
            if (this.testMinute > 60) {
              this.testHour = Math.floor(Number(this.testMinute) / 60);
              this.testMinute = this.testMinute % 60;
            } else {
              if (this.testMinute >= 60) {
                this.testMinute = Number(this.testMinute) % 60;
              }
              this.testHour = 0;
            }

            let subjectListData = res.data.data.paperSubjectList;
            let obj = {
              1:[],
              2:[],
              3:[],
              4:[],
              5:[],
              6:[],
              7:[],
              8:[],
              9:[],
              10:[],
              11:[],
            };
            subjectListData.forEach((v,i)=>{
              obj[v.classify].push(1);
              v.questionIndex = obj[v.classify].length;
            })
            this.AnswerList = res.data.data;

            this.loading.close();
            this.deltimer();
            var examTextInfo = localStorage.getItem("tiku_examText");
            if (examTextInfo) {
              examTextInfo = JSON.parse(examTextInfo);
              examTextInfo.value = 1;
              localStorage.setItem('tiku_examText',JSON.stringify(examTextInfo));
            }
            this.$nextTick(()=>{
              this.$refs.questionArea.getIsSign();
              this.$refs.questionArea.getIsCollect();
              this.update_size();
            })
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    init() {
      // 路由参数
      let pageQuery = this.$route.query;
      let tabsIndex = pageQuery.tabIndex;
      // 二级判断 做题记录的章节还是考试
      var examTextInfo = localStorage.getItem("tiku_examText");
      let examText = JSON.parse(examTextInfo);
      let items = examText.chapterListItem;
      let testPaperId = pageQuery.testPaperId;
      this.getSubjectPaperList({
          testPaperId: testPaperId,
          terrace:'2',
          type: this.num
      });




      
    },
    getAnswerTimeLength(times) {
      let arys = times.split(":");
      let time1 = parseInt(arys[0]) * 60 * 60;
      let time2 = parseInt(arys[1]) * 60;
      let time3 = parseInt(arys[2]);
      return time1 + time2 + time3;
    },
    //历年/模拟/考前押题 保存题
    saveSHIJUAN(res) {
      if(res.answerId == undefined){
        return;
      }
      
      let alllen = this.paperAnswerTime * 60;
      var subLen = alllen - this.getAnswerTimeLength(this.answerTimers);       

      if (res.type == "radio") {
        var obj = {
          answerTimeLength: subLen,
          subjectId: res.subjectId,
          testPaperId: this.$route.query.testPaperId,
          answerId: res.answerId,
          answerText: "",
          score: "",
        };
        savePaper_subject(obj).then((result) => { this.setDataItemValue(res) });
      } else if (res.type == "checkbox") {
        var obj = {
          answerTimeLength: subLen,
          subjectId: res.subjectId,
          testPaperId: this.$route.query.testPaperId,
          answerId: res.answerId.join(","),
          answerText: "",
          score: "",
        };
        savePaper_subject(obj).then((result) => { this.setDataItemValue(res) });
      } else if (res.type == "indeter") {
       var obj = {
          answerTimeLength: subLen,
          subjectId: res.subjectId,
          testPaperId: this.$route.query.testPaperId,
          answerId: res.answerId.join(","),
          answerText: "",
          score: "",
        };
        savePaper_subject(obj).then((result) => { this.setDataItemValue(res) });
      } else if (res.type == "shortAnswer") {
        var obj = {
          answerTimeLength: subLen,
          subjectId: res.subjectId,
          testPaperId: this.$route.query.testPaperId,
          answerId: '',
          answerText: res.doneAnswer,
          score: "",
        };
        savePaper_subject(obj).then((result) => { this.setDataItemValue(res) });
      }
    },
    setDataItemValue(res){
      //this.AnswerList.finallyAnswerId = res.subjectId;
      if(this.AnswerList.paperSubjectList[res.dataIndex].subjectList&&this.AnswerList.paperSubjectList[res.dataIndex].subjectList.length){
        let numQuestion = this.AnswerList.paperSubjectList[res.dataIndex].subjectList;
        numQuestion.map((item,index)=>{
          if(item.subjectId == res.subjectId){
            if(item.classify == '5' || item.classify == '6' || item.classify == '7' || item.classify == '8' || item.classify == '9' ){
              this.AnswerList.paperSubjectList[res.dataIndex].subjectList[index].doneAnswer = res.doneAnswer;
            }else if(item.classify == '10'){
              this.AnswerList.paperSubjectList[res.dataIndex].subjectList[index].doneAnswerIds = res.answerId;
            }else{
              this.AnswerList.paperSubjectList[res.dataIndex].subjectList[index].doneAnswerIds = res.answerId.join(",");
              this.AnswerList.paperSubjectList[res.dataIndex].doneAnswerIds = res.answerId.join(",");
            }
          }
        })
      }else {console.log(res.type);
        if(res.type==='checkbox'){
          this.AnswerList.paperSubjectList[res.dataIndex].doneAnswerIds = res.answerId.join(",");
        }
      }
      
    },
    allEventFn(res) {
      
      if(res.answerId == undefined){
        return;
      }
      var pageQuery = this.$route.query;
      // 二级判断 做题记录的章节还是考试
      var examTextInfo = localStorage.getItem("tiku_examText");
      let examText = JSON.parse(examTextInfo);
      this.saveSHIJUAN(res);
    },
    update_size() {
      if (
        document.getElementsByClassName("update_font_size") != undefined &&
        document.getElementsByClassName("update_font_size") != null
      ) {
        var sizes = document.getElementsByClassName("update_font_size");

        for (var i = 0; i < sizes.length; i++) {
          if (sizes[i].getElementsByTagName("font")) {
            var classsize = sizes[i].getElementsByTagName("font");

            for (var j = 0; j < classsize.length; j++) {
              classsize[j].setAttribute("size", "4px");
            }
          }
        }
      }
    },
    // 时间计时
    timer() {
      //定义计时函数
      this.second = Number(this.second) + 1;
      this.second_time = Number(this.second_time) + 1; //秒
      if (this.second >= 60) {
        this.second = 0;
        this.minute = this.minute + 1; //分钟
      }

      if (this.minute >= 60) {
        this.minute = 0;
        this.hour = this.hour + 1; //小时
      }

      this.str =
        this.toDub(this.hour) +
        ":" +
        this.toDub(this.minute) +
        ":" +
        this.toDub(this.second); /*+""+this.toDubms(this.ms)+"毫秒"*/
      this.answerTimers = this.str;
      // document.getElementById('mytime').innerHTML=h+"时"+m+"分"+s+"秒"+ms+"毫秒";
    },
    formatSeconds(value) {
      let res = parseInt(value);
      let h = Math.floor(res / 3600) < 10 ? '0' + Math.floor(res / 3600) : Math.floor(res / 3600);
      let m = Math.floor(res / 60 % 60) < 10 ? '0' + Math.floor(res / 60 % 60) : Math.floor(res / 60 % 60);
      let s = Math.floor(res % 60) < 10 ? '0' + Math.floor(res % 60) : Math.floor(res % 60);

      let str = '';
      str += `${h}:`;
      str += `${m}:`;
      str += s;
      return str;
    },
    //倒计时
    countDown() {
      this.timerFn = setTimeout(() => {
        this.time -= 1;
        this.str = this.formatSeconds(this.time);
        this.answerTimers = this.str;

        if (this.time <= 0) {
          this.$confirm("答题时间已到，系统自动交卷", "提示", {
            confirmButtonText: "确定",
            cancelButtonText:'1',
            showCancelButton:false,
            showClose:false,
            closeOnClickModal:false,
            type: "warning"
          })
          .then(() => {
            this.submitTestPa();
          })
          .catch(() => {
            this.submitTestPa();
          });
        }else {
          this.countDown();
        }
      }, 1000);
    },
    // 倒计时
    deltimer() {
      if((this.paperAnswerTime*60)>parseInt(this.recordTime)){
        if (parseInt(this.paperAnswerTime)) {
          this.time = this.paperAnswerTime*60 - parseInt(this.recordTime);
          this.str = this.formatSeconds(this.time);
          this.countDown();
        } 
      }else{
        this.$confirm("答题时间已到，系统自动交卷", "提示", {
            confirmButtonText: "确定",
            cancelButtonText:'1',
            showCancelButton:false,
            showClose:false,
            closeOnClickModal:false,
            type: "warning"
          })
          .then(() => {
            this.submitTestPa();
          })
          .catch(() => {
            this.submitTestPa();
          });

      }
      
    },
    toDub(n) {
      //补0操作
      if (n < 10) {
        return "0" + n;
      } else {
        return "" + n;
      }
    },
  },
};
</script>

<style lang="less" scoped>
#update_font_size {
  /deep/ font {
    size: 4px;
  }
}

.tikuAnswer_header{
  background: #72B5F1;
  display: flex;
  height: 94px;
  .left{
    width: 400px;
    height: 100%;
    text-align: left;
    font-weight: 600;
    color: #fff;
    display: flex;
    flex-direction: row;
    .img{
     
      width: 90px;
      height: 100px;
      margin-left: 20px;
      margin-right: 10px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .info{
      margin-top:10px;
      div{
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  .middle{
    flex: 1;
    font-weight: 600;
    color: #fff;
    padding: 20px;
  }
  .right{
    height: 100%;
    margin-right: 50px;
    .info{
      display: inline-block;
      margin-top: 20px;
      font-weight: 600;
      .title1{
        color: #F8FC0D;
        font-size: 18px;
      }
      .title2{
        color: #0F5B2B;
      }
      .title3{
        color: #F8FC0D;
      }
    }
    .btn{
      display: inline-block;
      vertical-align: top;
      margin-top: 20px;
      .sub{
        border-radius: 0;
        background: #FFC733;
        border: 1px solid #CC6600;
      }
    }
  }
}

.tikuAnswer_main{
  width: 100%;
  display: flex;
  height: calc(100vh - 130px);
  background: #DDEBF6;
  position: relative;
  .tikuAnswer_main_left{
    width: 296px;
    background: #EFF6FE;
    border: 1px solid #9FB7F7;
    border-bottom: none;
    padding: 0;
  }
  .tikuAnswer_main_right{
    flex: 1;
    height: 100%;
    border: 1px solid #9FB7F7;
    border-bottom: none;
    margin-left: 20px;
    //padding: 10px 10px 0;
    box-sizing: border-box;
    .markst{
      background: url('../../assets/tiku/n_42.gif') repeat-x;
      width: 100%;
      height: 29px;
      .markst_1{
        float: left;
        height: 29px;
        margin-right: 6px;
        width: 109px;
        font-size: 14px;
        font-weight: bolder;
        line-height: 29px;
        background: url('../../assets/tiku/markst.png') no-repeat;
        text-align: center;
      }
    }
  }

  .sanjiao{
    cursor: pointer;
    width: 10px;
    height: 60px;
    border-radius: 3px;
    background: #4592C9;
    position: absolute;
    top: calc(50% - 30px);
    .showsanjiao{
      position: absolute;
      top: 23px;
      left: 0;
      height: 5px;
      border-top: 5px solid transparent;
      border-right: 5px solid transparent;
      border-left: 5px solid #fff;
      border-bottom: 5px solid transparent;
    }
    .hidesanjiao{
      position: absolute;
      top: 23px;
      left: -5px;
      height: 5px;
      border-top: 5px solid transparent;
      border-left: 5px solid transparent;
      border-right: 5px solid #fff;
      border-bottom: 5px solid transparent;
    }
  }
  .shows{
    left: 296px;
  }
  .hides{
    left: 0px;
  }
}

.footer{
  height: 25px;
  width: 100%;
  background: #DDEBF6;
  border: 1px solid #9FB7F7;
}
</style>
<style>
</style>