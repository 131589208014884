<template>
  <div class="tiku_radio">
    <div class="tiku_radio_title" v-if="data.classify == '1'">
      单项选择题（每小题2分。每小题备选答案中，只有一个符合题意的正确答案。错选、不选均不得分。请使用计算机鼠标在计算机答题界面上点击试题答案备选项前的按钮“○”作答。）
    </div>
    <div class="tiku_radio_title" v-if="data.classify == '3'">
      判断题（每小题1分。请判断每小题的表述是否正确。每小题答题正确的得1分，错答、不答均不得分，也不扣分。请使用计算机鼠标在计算机答题界面上点击试题答案备选项前的按钮“○”作答。）
    </div>
    <div class="tiku_radio_question_box">
      <div style="font-weight: bold;font-size: 17px;">
        第 {{data.questionIndex}} 题
      </div>
      <div class="tiku_radio_question_title">
        {{ data.subjectTitle }}
      </div>
      <div class="tiku_radio_question_radio_box">
        <el-radio-group v-model="data.doneAnswerIds" @input="radioChange">
          <el-radio
            class="f-v-c"
            v-for="(item, index) in data.answerList"
            :key="index"
            :label="item.answerId"
          >
            {{ item.indexes }}、<span v-if="item.type == 1" class="textWrap">{{
              item.answerName
            }}</span>
          </el-radio>
        </el-radio-group>
      </div>
    </div>
    <div v-if="isShowJX" class="tiku_radio_radioAnalysis_header">
      <div class="tiku_radio_radioAnalysis_header_MyQuestion">
        <p>
          <span>您的作答：</span><span>{{ setMyAnwser(anaList) }}</span>
        </p>
        <p>
          <span>参考答案：</span><span>{{ setRightAnwser(anaList) }}</span>
        </p>
        <p
          v-if="
            questionObj.totalAccuracy != '' &&
            questionObj.totalAccuracy != null &&
            questionObj.totalAccuracy != undefined
          "
        >
          <span>全站正确率：</span>
          <span>{{ questionObj.totalAccuracy }}</span>
        </p>
      </div>
      <div class="tiku_radio_radioAnalysis_header_numQuestion">
        <p>
          <span>累计作答：</span><span>{{ questionObj.countNumber }}次</span>
        </p>
        <p>
          <span>答对：</span><span>{{ questionObj.rightNumber }}次</span>
        </p>
        <p>
          <span>答错：</span>
          <span>{{ questionObj.errorNumber }}次</span>
        </p>
      </div>
      <div class="tiku_radio_radioAnalysis_header_anwserAna">
        <span>答题解析:</span>
        <span v-html="anaList.analysisText"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { subject_analysis,questionStatistics } from "@/api/exam.js";
import { Loading } from "element-ui";
export default {
  props: ["data", "dataIndex"],
  components: {},
  data() {
    return {
      radio: "",
      isShowJX: false,
      anaList: {},
      questionObj:{}
    };
  },
  name: "radioCom",
  computed: {
    Obj() {
      return this.$store.state.studyList.questionSwipeIndex.index;
    },
  },
  watch: {
    Obj(oldValue, newValue) {
      if (this.$store.getters.getQuestionSwipeIndex.index == this.dataIndex) {
        this.initAnalysis();
      } else {
        this.isShowJX = false;
      }
      // if(this.$store.getters.getResolution_method.key == 'header_res'){

      // }else{
      //   this.isShowJX = 'yes';
      // }
    },
  },
  methods: {
    getAnswer(){
      // this.save_subject_Tab(this.checkList);
      return 'radio';
    },
    setMyAnwser(list) {
      if (list.answerList && list.answerList.length > 0) {
        let val = list.answerList.filter((item) => {
          return list.doneAnswerIds === item.answerId;
        });
        if (val.length == 0) {
          return "/";
        } else {
          return val[0].indexes;
        }
      }
    },
    setRightAnwser(list) {
      if (list.answerList && list.answerList.length > 0) {
        let val = list.answerList.filter((item) => {
          return item.isRight == "1";
        });
        return val[0].indexes;
      }
    },
    radioChange(val) {
      let obj = {
        subjectId: this.data.subjectId,
        answerId: val,
        dataIndex: this.dataIndex,
        type: "radio",
      };
      this.$emit("answerRadio", obj);
    },
    initAnalysis() {
      this.loading = Loading.service({
        text: "解析中...",
        background: "rgba(0, 0, 0, 0.1)",
      });
      subject_analysis({ subjectId: this.data.subjectId }).then((res) => {
        this.isShowJX = this.$store.getters.getQuestionSwipeIndex.isShow;
        this.anaList = res.data.data;
        this.loading.close();
      });
      questionStatistics({subjectId: this.data.subjectId}).then((res)=>{
        this.questionObj = res.data.data;
      })
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less">
.tiku_radio_title{
  font-weight: 500;
  text-align: left;
  border-bottom: 1px solid #9FB7F7;
}
</style>