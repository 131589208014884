<template>
  <div class="answerCard">
    <div class="warp">
      <template v-for="item in list">
        <p class="title" @click="show(item)">{{ item.name }} <span class="titlebtn" v-if="item.show">-收起</span><span class="titlebtn" v-else>+展开</span></p>
        <div class="content" v-show="item.show">
          <template v-for="(val,index) in item.children">
            <div class="sign p" @click="jump(val)">
              <div class="t">
                {{ (index + 1) }}
                <span v-if="val.isSign == '1'" style="color: #ff0000;">？</span>
                <span v-else style="color: #ff0000;">{{ (val.doneAnswerIds||val.doneAnswer||val.answerId)?'':'*'}}</span>
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>
    <div class="examText_main_left_explain">
      <div>说明：</div>
      <div>1.题号右方的 "*" 符号代表该小题尚未选择答案。</div>
      <div>2.题号右方的 "?" 符号代表该小题做过标记。</div>
      <div>3.选择选项的同时自动保存答案。</div>
    </div>

    <div style="margin-top: 40px;margin-left: 20px;text-align: left">
      <el-popover
          :placement="'right'"
          width="300"
          trigger="hover"
          popper-class="calculator"
          v-model="visible"
      >
        <div id="box">
          <table>
            <tr>
              <td>
                <input type="button" value="del" @click="del()" />
              </td>
              <td>
                <input type="button" value="C" @click="clean()" />
              </td>
              <td colspan="2">
                <input type="text" style="width: 100px" value v-model="rel" />
              </td>
            </tr>
            <tr>
              <td>
                <input type="button" value="7" @click="add('7')" />
              </td>
              <td>
                <input type="button" value="8" @click="add('8')" />
              </td>
              <td>
                <input type="button" value="9" @click="add('9')" />
              </td>
              <td>
                <input type="button" value="/" @click="add('/')" />
              </td>
            </tr>

            <tr>
              <td>
                <input type="button" value="4" @click="add('4')" />
              </td>
              <td>
                <input type="button" value="5" @click="add('5')" />
              </td>
              <td>
                <input type="button" value="6" @click="add('6')" />
              </td>
              <td>
                <input type="button" value="*" @click="add('*')" />
              </td>
            </tr>

            <tr>
              <td>
                <input type="button" value="1" @click="add('1')" />
              </td>
              <td>
                <input type="button" value="2" @click="add('2')" />
              </td>
              <td>
                <input type="button" value="3" @click="add('3')" />
              </td>
              <td>
                <input type="button" value="-" @click="add('-')" />
              </td>
            </tr>

            <tr>
              <td>
                <input type="button" value="0" @click="add('0')" />
              </td>
              <td>
                <input type="button" value="." @click="add('.')" />
              </td>
              <td>
                <input type="button" value="+" @click="add('+')" />
              </td>
              <td>
                <input type="button" value="=" v-on:click="result()" />
              </td>
            </tr>
          </table>
        </div>

        <span slot="reference" @click="visible = !visible">
<!--              <span style="cursor:pointer;color:#000;font-size: 16px;font-weight: 600;">计算器</span>-->
          <img style="cursor:pointer;" src="../../../assets/tiku/calculator.png" alt="">
        </span>
      </el-popover>
    </div>
  </div>
</template>

<script>
import {
  subject_list,
  subjectPaperList
} from "@/api/exam";

export default {
  name: 'answerCard',
  components: {},
  props: ["AnswerList"],
  watch: {
    AnswerList: {
      handler(val) {
        this.getData(val);
      },
      deep: true
    }
  },
  data() {
    return {
      visible: false, // 是否显示计算器
      rel: "", // 计算
      list: [],
      typeList: [
        {type: '1', name: '单项选择题',show: true},
        {type: '2', name: '多项选择题',show: true},
        {type: '3', name: '判断题',show: true},
        {type: '4', name: '不定项选择题',show: true},
        {type: '5', name: '计算分析题',show: true},
        {type: '6', name: '综合题',show: true},
        {type: '7', name: '简答题',show: true},
        {type: '8', name: '计算回答题',show: true},
        {type: '9', name: '案例分析题',show: true},
        {type: '10', name: '计算题',show: true},
        {type: '11', name: '综合分析题',show: true}
      ],
      completeNumber: 0,
      questionData: {}
    }
  },
  mounted(){
    // setTimeout(()=>{
    //   this.getData(this.AnswerList);
    // },1500)
  },
  methods: {
    getData(data) {
      this.list = [];
      console.log(data);
      // this.completeNumber = data.finallyAnswerId||data.paperSubjectList[0].subjectId;
      this.completeNumber = data.finallyAnswerId||data.paperSubjectList[0].subjectId;
      this.questionData = data.paperSubjectList;
      this.typeList.map((v,i) => {
        if(data.paperSubjectList) {
          let paper = data.paperSubjectList.filter(val => val.classify === v.type);
          if (paper.length) {
            this.list.push({name: v.name, children: paper,typeListIndex:i,show:v.show});
          }
        }
      })
    },
    show(item) {
      item.show = !item.show;
      this.typeList[item.typeListIndex].show = item.show;
    },
    jump(data,subjectList,index1) {
      this.completeNumber = data.subjectId;
      this.AnswerList.finallyAnswerId = data.subjectId;
      let index = 0;
      this.questionData.map((v,i)=>{
        if(v.subjectId === data.subjectId) {
          index = i;
        }
      })
      if(subjectList) {
        this.completeNumber = subjectList.subjectId;
        this.AnswerList.finallyAnswerId = subjectList.subjectId;
        this.$emit('jumpTo',index,subjectList.subjectId,index1);
      }else {
        this.completeNumber = data.subjectId;
        this.$emit('jumpTo',index);
      }
    },
    jumpTo(data,two) {
      this.questionData.map((v,i)=>{
        if(i === data) {
          if(v.subjectList.length) {
            this.AnswerList.finallyAnswerId = v.subjectList[two||0].subjectId;
            this.completeNumber = v.subjectList[two||0].subjectId;
          }else {
            this.AnswerList.finallyAnswerId = v.subjectId;
            this.completeNumber = v.subjectId;
          }
        }
      })
    },
    goQuestion(data) {
      this.completeNumber = data.subjectId;
    },
    // 计算器
    add(index) {
      //这里就是按键绑定的方法，把获取到的值拼接到rel字符串上
      this.rel += index;
    },
    result() {
      this.rel = eval(this.rel); //这里是用eval方法进行一个计算
      this.rel = String(this.rel); //这里的目的是为了显示在显示栏的数字还是字符串，只有字符串才能进行退格，归零的操作
    },
    del() {
      //这个就是退格的操作，用字符串的substring方法截取，每一次截取都是从第0个开始，到长度的前一个，就相当于退格了。
      this.rel = this.rel.substring(0, this.rel.length - 1);
    },
    clean() {
      //这里就是归零的方法，通过给结果赋一个空字符串的方法来实现归零的操作，当然也可以使用删除的方法，例如unshift方法或者pop方法，直接赋值为空比较简便。
      this.rel = "";
    }
  }
}
</script>

<style lang="less" scoped>
.answerCard {
  .warp {
    .title {
      width: 100%;
      color: #000;
      font-weight: 600;
      padding: 0 20px 5px 20px;
      box-sizing: border-box;
      //display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid #9FB7F7;
      position: relative;
      background: #6DB1E2;
      cursor: pointer;
      text-align: center;
      height: 30px;
      line-height: 30px;
      .titlebtn{
        font-size: 14px;
        position: absolute;
        top: 0;
        right: 3px;
        color: #1B4E8C;
      }
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      padding: 3px;
      box-sizing: border-box;

      .p {
        min-width: 30px;
        width: auto;
        height: 30px;
        box-sizing: border-box;
        //border: 1px solid #DDDDDD;
        //border-radius: 50%;
        margin-right: 5px;
        font-size: 16px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #0303EE;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        background-color: #DDEBF6;
        cursor: pointer;
        .t{
          border-bottom: 1px solid #0303EE;
        }
      }

      .doneQuestion {
        background-color: #DCE3FF !important;
        color: #5F7CFD;
      }

      .curQuestion {
        background-color: #5F7CFD !important;
        color: #FFFFFF !important;
      }
    }
  }

  .sign {
    //border: 1px solid #DDDDDD;
    //border-radius: 50%;
    position: relative;
    overflow: hidden;
    width: 18px;
    height: 18px;

/*    span {
      width: 5px;
      height: 4px;
      font-size: 8px;
      line-height: 32px;
      background: orange;
      position: absolute;
      right: 0;
      bottom: 0;
      z-Index: 2;
      padding: 0 2em;
      -webkit-transform-origin: left top;
      -moz-transform-origin: left top;
      transform-origin: left top;
      -webkit-transform: translate(29.29%, 100%) rotate(-45deg);
      -moz-transform: translate(29.29%, 100%) rotate(-45deg);
      transform: translate(29.29%, 100%) rotate(-45deg);
      text-indent: 0;
    }*/
  }

  .examText_main_left_explain{
    border-top: none;
    padding:0;
    font-size: 14px;
    color: #ff0000;
    text-align: left;
  }
}
</style>