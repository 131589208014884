<template>
  <div class="tiku_indeterminate">
    <div class="tiku_indeterminate_header">
      不定项选择题（每小题2分。每小题备选答案中，有一个或一个以上符合题意的正确答案。每小题全部选对得满分，少选得相应分值，多选、错选、不选均不得分。请使用计算机鼠标在计算机答题界面上点击试题答案备选项前的按钮“□”作答。）
    </div>
    <div class="tiku_indeterminate_question">
      <div style="font-weight: bold;font-size: 17px;">
        第 {{data.questionIndex}} 题
      </div>
    </div>

    <div class="tiku_warp">
      <div class="tiku_left">
        <div class="tiku_indeterminate_question_title update_font_size">
          <!--        <span>{{data.questionIndex}}、</span>-->
          <span v-html="data.subjectTitle"></span>
          <img v-if="data.subjectTitlePic" :src="data.subjectTitlePic" class="subjectTitlePic">
        </div>
      </div>
      <div class="xian"></div>
      <div class="tiku_right">
        <div v-for="(item1,index) in data.subjectList">
          <div class="tiku_indeterminate_question_text">
            {{index+1}}、{{item1.subjectTitle}}
            <img v-if="item1.subjectTitlePic" :src="item1.subjectTitlePic" class="subjectTitlePic">
          </div>
          <div class="examText_main_right_checkBox_question_option">
            <div class="examText_main_right_checkBox_question_item">
              <el-checkbox-group v-model="checkList[index]" @change="checked=>save_subject_Tab(checked,index)">
                <el-checkbox class="f-v-c" v-for="(item,index) in item1.answerList" :key="index" :label="item.answerId">{{item.indexes}}、{{item.answerName}}<img :src="item.answerPic" class="answerPic" alt="" v-if="item.type==2"></el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>
      </div>
    </div>

<!--答案解析-->
    <div class="tiku_indeterminate_radioAnalysis_nalysisWarp">
      <div v-if="isShowJX" class="tiku_indeterminate_radioAnalysis_header">
        <div class="tiku_indeterminate_radioAnalysis_header_MyQuestion">
          <p>
            <span>您的作答：</span><span>{{ setMyAnwser(anaList) }}</span>
          </p>
          <p>
            <span>参考答案：</span><span>{{ setRightAnwser(anaList) }}</span>
          </p>
          <p
              v-if="
            questionObj.totalAccuracy != '' &&
            questionObj.totalAccuracy != null &&
            questionObj.totalAccuracy != undefined
          "
          >
            <span>全站正确率：</span>
            <span>{{ questionObj.totalAccuracy }}</span>
          </p>
        </div>
        <div class="tiku_indeterminate_radioAnalysis_header_numQuestion">
          <p>
            <span>累计作答：</span><span>{{ questionObj.countNumber }}次</span>
          </p>
          <p>
            <span>答对：</span><span>{{ questionObj.rightNumber }}次</span>
          </p>
          <p>
            <span>答错：</span>
            <span>{{ questionObj.errorNumber }}次</span>
          </p>
        </div>
        <div class="tiku_indeterminate_radioAnalysis_header_anwserAna">
          <div class="tiku_indeterminate_radioAnalysis_header_anwserAna_title">答题解析：</div>
          <div class="tiku_indeterminate_radioAnalysis_header_anwserAna_text">
            <p v-html="anaList.analysisText"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {subject_analysis,questionStatistics} from "@/api/exam";
import { Loading } from "element-ui";
export default {
  props: ["data", "dataIndex"],
  components: {},
  data() {
    return {
      isShowJX: false,
      smallQuestionIndex: 0,
      anaList: {},
      questionObj:{},
      curData: {},
      checkList: []
    };
  },
  name: "indeterminate",
  computed: {

  },
  watch: {

  },
  methods: {
    changeAnswer(index){
      this.data.subjectList.map((v,i)=>{
        this.checkList[i] = v.doneAnswerIds?v.doneAnswerIds.split(','):[];
      })
    },
    getAnswer(){

      return this.smallQuestionIndex;
    },
    setAnswer(index){
      this.data.subjectList.map((v,i)=>{
        this.checkList[i] = v.doneAnswerIds?v.doneAnswerIds.split(','):[];
      })

    },
    startAnaly(){
      this.initAnalysis();
    },
    setMyAnwser(list) {
      if (list.answerList && list.answerList.length > 0) {
        let val = list.answerList.filter((item) => {
          return list.doneAnswerIds === item.answerId;
        });
        if (val.length == 0) {
          return "/";
        } else {
          return val[0].indexes;
        }
      }
    },
    setRightAnwser(list) {
      if (list.answerList && list.answerList.length > 0) {
        let val = [];
        list.answerList.map((item) => {
          if(item.isRight === 1) {
            val.push(item.indexes)
          }
        });
        return val.join(',');
      }
    },
    tabSmallQuestion(val,index) {

    },
    save_subject_Tab(val,index) {
      this.smallQuestionIndex = index;
      let subjectId = this.data.subjectList.length?this.data.subjectList[this.smallQuestionIndex].subjectId:this.data.subjectId;
      let obj = {
        subjectId: subjectId,
        answerId: val,
        dataIndex: this.dataIndex,
        type: "indeter",
      };
      this.$emit("answerRadio", obj);
    },
    changeQuestion(type) {
      if(type === 'prev'&&this.smallQuestionIndex===0) {

      }else if(type === 'next'&&this.smallQuestionIndex===this.data.subjectList.length-1) {

      }else {
        let index = type === 'prev'?this.smallQuestionIndex-1:this.smallQuestionIndex+1;
        this.tabSmallQuestion(index);
      }
    },
    initAnalysis() {
      this.loading = Loading.service({
        text: "解析中...",
        background: "rgba(0, 0, 0, 0.1)",
      });
      subject_analysis({ subjectId: this.curData.subjectId }).then((res) => {
        this.isShowJX = !this.isShowJX;
        // this.isShowJX = this.$store.getters.getQuestionSwipeIndex.isShow;
        this.anaList = res.data.data;
        this.loading.close();
      });
      questionStatistics({subjectId: this.curData.subjectId}).then((res)=>{
        this.questionObj = res.data.data;
      })
    },
    setTitle(item){
      switch(item.classify){
        case "1":
          return "单项选择题";
          break;
        case "2":
          return "多项选择题";
          break;
        case "3":
          return "判断题";
          break;
        case "4":
          return "不定项选择题";
          break;
        case "5":
          return "计算分析题";
          break;
        case "6":
          return "综合题";
          break;
        case "7":
          return "简答题";
          break;
        case "8":
          return "计算回答题";
          break;
        case "9":
          return "案例分析题";
          break;
        case "10":
          return "计算题";
          break;
        case "11":
          return "综合分析题";
          break;

      }

    },
  },
  created() {
    this.data.subjectList.map((v,i)=>{
      this.checkList[i] = v.doneAnswerIds?v.doneAnswerIds.split(','):[];
    })
  },
  mounted() {

    
  },
};
</script>
<style lang="less">
.tiku_indeterminate{
  height: 100%;
}
.tiku_indeterminate_header{
  font-weight: 500;
  text-align: left;
  height:90px;
  border-bottom: 1px solid #9FB7F7;
}

.tiku_indeterminate_question{
  border: none;
  height: auto;
  min-height: auto;
}
.tiku_warp{
  display: flex;
  padding: 0 20px;
  position: relative;
  .tiku_left{
    width: 40%;
    height: calc(100vh - 270px);
    overflow: hidden;
    overflow-y: auto;
  }
  .xian{
    position: absolute;
    top: 0;
    left: calc(40% + 20px);
    height: calc(100vh - 270px);
    width: 6px;
    overflow: hidden;
    background: #a1b5fa;
  }
  .tiku_right{
    width: calc(60% - 20px);
    height: calc(100vh - 270px);
    overflow: hidden;
    overflow-y: auto;
    padding-left: 20px;
  }
}
</style>